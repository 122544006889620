<template>
  <div class="resume-contest-apply">
    <div class="contest-head">
      <div class="head-left">
        <el-image
          class="head-icon"
          :src="require(`../../../assets/image/recommend.png`)"
          fit="contain"
        ></el-image>
        <div class="head-title">AI简历大赛</div>
      </div>
      <el-image
        class="head-right"
        @click="sendExit"
        :src="require(`../../../assets/image/exit2.png`)"
        fit="contain"
      ></el-image>
    </div>
    <div class="contest-main">
      <div class="main-head">
        <div class="head-title">
          <span>使用AI简历</span>
        </div>
        <div class="head-data" @click="gotoSubmit()">
          <el-radio v-model="reqData.radio" label="1" />
          <el-image
            class="template-pic"
            src="https://bmbshopping.oss-cn-hangzhou.aliyuncs.com/shopgoods/0.84071404177117781716518384000.jpg"
            fit="contain"
          ></el-image>
        </div>
      </div>
      <div class="main-bottom">
        <div class="bottom-title">
          <span>使用自己的简历</span>
        </div>
        <div class="bottom-data" @click="reqData.radio = '2'">
          <el-radio v-model="reqData.radio" label="2" />
          <div>
            <el-image
              v-if="pdfDisable"
              class="template-pic m-r"
              :src="require(`../../../assets/image/pdf.png`)"
              fit="contain"
            ></el-image>
            <el-image
              @click="addPdfPic"
              class="template-pic m-r"
              :src="require(`../../../assets/image/vita_up.png`)"
              fit="contain"
            ></el-image>
            <el-image
              v-if="pathDisable"
              class="template-pic"
              :src="require(`../../../assets/image/path.png`)"
              fit="contain"
            ></el-image>
            <el-image
              @click="addPathPic"
              class="template-pic"
              :src="require(`../../../assets/image/accessory_up.png`)"
              fit="contain"
            ></el-image>
          </div>
        </div>
        <el-button class="signUp" @click="signUp" size="small" type="primary"
          >报名</el-button
        >
      </div>
    </div>
    <!-- 上传图片弹窗 -->
    <MyDialog
      :dialogVisible="dialogVisible"
      @changeDialog="changeDialog"
      @getPic="getPic"
      :limit="limit"
    />
  </div>
</template>

<script>
import MyDialog from "../../../components/MyDialog.vue";
import { insertResume, checkSubmit } from "../../../request/api";

export default {
  components: {
    MyDialog,
  },
  data() {
    return {
      reqData: {
        radio: "",
        pdfImg: "",
        pathImg: "",
        resume_my_id: "",
      },
      pdfDisable: false,
      pathDisable: false,
      // 控制弹窗显示
      dialogVisible: false,
      limit: 0,
      isPdf: null,
    };
  },
  methods: {
    sendExit() {
      this.$emit("update-value");
    },
    async gotoSubmit() {
      this.reqData.radio = "1";

      try {
        const res = await checkSubmit();
        if (res.status !== 200 || res.data.code !== "1000") {
          this.$message.error(res.data.msg);
          return;
        }

        if (res.data.data === false) {
          this.$router.push("resumeContest");
        } else {
          this.reqData.resume_my_id = res.data.data;
        }
      } catch (error) {
        this.$message.error("检查在线简历错误");
      }
    },
    async signUp() {
      if (this.reqData.radio === "2" && this.reqData.pdfImg === "") {
        return this.$message.warning("请上传pdf简历文件");
      }

      try {
        const res = await insertResume(this.reqData);
        if (res.status !== 200 || res.data.code !== "1000") {
          this.$message.error(res.data.msg);
          return;
        }

        this.$message.success("报名成功");
        this.$router.push({
          path: "resumeContestPreview",
          query: {
            resume_id: res.data.data,
          },
        });
      } catch (error) {
        this.$message.error("报名失败，请刷新后重试");
      }
    },
    addPdfPic() {
      this.limit = 1;
      this.isPdf = true;
      this.dialogVisible = true;
    },
    addPathPic() {
      this.limit = 1;
      this.isPdf = false;
      this.dialogVisible = true;
    },
    // 关闭弹窗
    changeDialog() {
      this.dialogVisible = false;
    },
    // 组件保存图片
    getPic(picArr) {
      this.dialogVisible = false;
      picArr.forEach((pic) => {
        if (this.isPdf === true) {
          this.reqData.pdfImg = pic;
          this.pdfDisable = true;
        } else {
          this.reqData.pathImg = pic;
          this.pathDisable = true;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.resume-contest-apply {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .contest-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    .main-head {
      height: 50%;
      border-bottom: 1px solid #4d94ff;
      display: flex;
      flex-direction: column;
      .head-title {
        display: flex;
        align-items: center;
        font-weight: bold;
        color: #4d94ff;
        height: 10%;
      }
      .head-data {
        flex: 1;
        padding: 10px;
        display: flex;
        align-items: center;
        :deep(.el-radio__label) {
          display: none;
        }
        .template-pic {
          width: 200px;
        }
        &:hover {
          background-color: #e5ebf8;
          cursor: pointer;
        }
      }
    }
    .main-bottom {
      flex: 1;
      display: flex;
      flex-direction: column;
      .signUp {
        width: 200px;
        background-color: #f29a0f;
        border: none;
        &:hover {
          background-color: #ffd14e;
        }
      }
      .bottom-title {
        display: flex;
        height: 10%;
        align-items: center;
        font-weight: bold;
        color: #4d94ff;
      }
      .bottom-data {
        flex: 1;
        padding: 10px;
        display: flex;
        align-items: center;
        :deep(.el-radio__label) {
          display: none;
        }
        .m-r {
          margin-right: 16px;
        }
        .template-pic {
          width: 160px;
        }
        &:hover {
          background-color: #e5ebf8;
          cursor: pointer;
        }
      }
    }
  }
  .contest-head {
    height: 8%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .head-left {
      display: flex;
      align-items: center;
      .head-icon {
        width: 40px;
        margin-right: 6px;
      }
      .head-title {
        font-size: 14px;
        color: #7a7c7e;
      }
    }
    .head-right {
      padding-right: 1%;
      width: 70px;
      cursor: pointer;
    }
  }
}
</style>
